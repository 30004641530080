import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import GUI from 'lil-gui'
import './style.scss'

import gsap from "gsap";

import Confetti from './confetti.js';


import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'
import firefliesVertexShader from './shaders/fireflies/vertex.glsl'
import firefliesFragmentShader from './shaders/fireflies/fragment.glsl'

// 1
    // Créer autant de carré que nécessaire -- OK
    // Case => cadenas / ok / faux / en attente -- OK
    // Mettre en place le CSS ==> SCSS -- OK
    // AddEvent sur carré -- OK
    // Popin => bouton "valider" -- OK
    // Quand on clique sur carré => montrer la question avec l'input réponse -- OK
    // Popin => close + question + pas encore le bon moment + "ouii" + "non" -- OK
    // Faire le déblocage d'item si répondu à la question précédente + 24h de passer -- OK
    // Voir comment changer le json -- OK
    // Faire la verif avec l'heure -- OK
    

    
// Mettre en place le design basic
// Mettre en place le design 3D
// Mettre en place les questions sur la BDD + JSON
// Deployer en vrai production




// import data from './questions.json';

var env = 'prod'; // prod

var dom = {};
var jsonFile = false;
var jsonFileStart = false;
var jsonFileEnd = false;
var popinOpen = false;
var questionOpenIndex = false;
var questionOpenDOM = false;
var nateNow = false;
var count_question = 0;
var confettiClass = false;

// Start
var mouseX = 0;
var mouseY = 0;
var currentX = 0;
var currentY = 0;
var isLockStep = false;


var classes = {

    webgl: 'js-webgl',
    confetti: 'js-confetti-canvas ',

    sceneContainer: 'js-scene-container',

    questionContainer: 'js-question-container',
    questionItem: 'js-question-item',
    questionDOMItem: 'question__item',
    questionDOMItemContent: 'question__item__content',
    questionDOMItemBorder: 'question__item__border',

    lockContainer: 'js-lock-container',
    lockTitle: 'js-lock-title',
    lockInput: 'js-lock-input',
    lockButton: 'js-lock-button',
    lockError: 'js-lock-error',
    lockMouse: 'js-lock-anim-mouse',
    lockSuccess: 'js-lock-success',

    popinContainer: 'js-popin-container',
    popinClose: 'js-popin-close',
    popinCloseCross: 'js-popin-close-cross',
    popinBorderTop: 'js-popin-border-top',
    popinBorderRight: 'js-popin-border-right',
    popinBorderBottom: 'js-popin-border-bottom',
    popinBorderLeft: 'js-popin-border-left',
    popinContent: 'js-popin-content',
    popinQuestion: 'js-popin-question',
    popinQuestionContent: 'js-popin-question-content',
    popinInput: 'js-popin-input',
    popinButton: 'js-popin-button',
    popinInfoWaiting: 'js-popin-info-waiting',
    popinInfoFast: 'js-popin-info-fast',
    popinError: 'js-popin-error',
    popinSuccess: 'js-popin-success',

    endContainer: 'js-end-container',
    endContent: 'js-end-content',
    endContentText: 'js-end-content-text',
    endText: 'js-end-text',
    endClose: 'js-end-close',
    endCloseCross: 'js-end-close-cross',
    
    success: 'success',
    error: 'error',
    current: 'current',
    pending: 'pending',
    show: 'show',
    showInfoWaiting: 'show-info-waiting',
    showInfoFast: 'show-info-fast',
    toAnimate: 'to-animate',
    active: 'active',
    reverse: 'reverse'
};

var list_name = [
    'floor',
    'end',
    'start',
    'middle',
    'wing_1',
    'wing_2',
    'pipe_1',
    'pipe_2',
    'shot_1',
    'shot_2',
    'shooting_support',
    'shot_3',
    'wing_side_1',
    'wing_side_2',
    'top_deco',
    'bottom_1',
    'bottom_2',
    'bottom_3',
    'bottom_4',
    'bottom_5',
    'side_1',
    'side_2',
    'end_deco',
    'wing_side_3',
    'wing_side_4',
    'middle_deco',
    'wing_side_5',
    'wing_side_6'
];



/**
 * Loaders
 */
// Texture loader
const textureLoader = new THREE.TextureLoader()

// Draco loader
const dracoLoader = new DRACOLoader()
dracoLoader.setDecoderPath('draco/')

// GLTF loader
const gltfLoader = new GLTFLoader()
gltfLoader.setDRACOLoader(dracoLoader)

var bakedTexture = false;

/**
 * Materials
 */
// Baked material
const bakedMaterial = new THREE.MeshBasicMaterial({ map: bakedTexture })

var list_model_3d = {};
var list_light_3d = {};
var init_model_3d_loaded = false;
var json_loaded = false;
var init_project_first_time = false;
var init_end_first_time = false;


init_project();
init_model_3d();

function init_project() {

    retrieve_DOM();

    if( env == 'prod' ) {

        let datatoSend = new FormData();
        datatoSend.append( 'initProject', true );
    
        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/php/ajax.php', true);
        xhr.onreadystatechange = function () {
            if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
                jsonFileStart = JSON.parse( xhr.response );
                init_project_response();
            }
        }.bind(this);
        xhr.send(datatoSend);
    }

    if( env == 'dev' ) {

        fetch('/json/start.json')
        .then( ( response ) => response.json() )
        .then( ( json ) =>  { 
            jsonFileStart = json;
            init_project_response();
        } );
    }
}

function retrieve_DOM() {
   
    dom.body = document.body;

    dom.webgl = dom.body.querySelector( '.' + classes.webgl );
    dom.confetti = dom.body.querySelector( '.' + classes.confetti );

    dom.scene = {
        container: dom.body.querySelector( '.' + classes.sceneContainer )
    };

    dom.question = {
        container : dom.body.querySelector( '.' + classes.questionContainer )
    };

    dom.lock = {
        container : dom.body.querySelector( '.' + classes.lockContainer ),
        title : dom.body.querySelector( '.' + classes.lockTitle ),
        input : dom.body.querySelector( '.' + classes.lockInput ),
        button: dom.body.querySelector( '.' + classes.lockButton ),
        error: dom.body.querySelector( '.' + classes.lockError ),
        mouse: dom.body.querySelector( '.' + classes.lockMouse ),
        success: dom.body.querySelector( '.' + classes.lockSuccess ),
    };

    dom.popin = {
        container : dom.body.querySelector( '.' + classes.popinContainer ),
        close : {
            container : dom.body.querySelector( '.' + classes.popinClose ),
            cross : dom.body.querySelector( '.' + classes.popinCloseCross ),
        },
        content : dom.body.querySelector( '.' + classes.popinContent ),
        border: {
            top: dom.body.querySelector( '.' + classes.popinBorderTop ),
            right: dom.body.querySelector( '.' + classes.popinBorderRight ),
            bottom: dom.body.querySelector( '.' + classes.popinBorderBottom ),
            left: dom.body.querySelector( '.' + classes.popinBorderLeft ),
        },
        question : {
            container: dom.body.querySelector( '.' + classes.popinQuestion ),
            content: dom.body.querySelector( '.' + classes.popinQuestionContent )
        },
        input : dom.body.querySelector( '.' + classes.popinInput ),
        button: dom.body.querySelector( '.' + classes.popinButton ),
        info: {
            waiting : dom.body.querySelector( '.' + classes.popinInfoWaiting ),
            fast : dom.body.querySelector( '.' + classes.popinInfoFast )
        },
        error: dom.body.querySelector( '.' + classes.popinError ),
        success: dom.body.querySelector( '.' + classes.popinSuccess ),
    };

    dom.end = {
        container: dom.body.querySelector( '.' + classes.endContainer ),
        close : {
            container : dom.body.querySelector( '.' + classes.endClose ),
            cross : dom.body.querySelector( '.' + classes.endCloseCross ),
        },
        content: dom.body.querySelector( '.' + classes.endContent ),
        contentText: dom.body.querySelector( '.' + classes.endContentText ),
        text: dom.body.querySelector( '.' + classes.endText ),
    }
}


function init_project_response() {
    
    // Manage content data
    if( jsonFileStart.end_game_unlock ) {
        init_end_first_time = true;
        start_end_project();
    } else if( jsonFileStart.unlocked ) {
        start_unlock_project();
    } else {
        start_lock_project();
    }
}

function init_show_model_3d() {

    for (let index in jsonFile) {

        if( jsonFile.hasOwnProperty( index ) ) {

            let nextIndex = parseInt(index) + 1;

            if( 
                ( index == 0 && jsonFile[ index ].state == 'pending' )
                || ( jsonFile[ index ].state == 'success' && jsonFile[ nextIndex ] && jsonFile[ nextIndex ].state == 'pending' )
            ) {

                bakedTexture = textureLoader.load(
                    'model/step/' + jsonFile[ index ].model_baking + '.jpg',
            
                    // onLoad callback
                    function ( texture ) {

                        set_baked_texture();
                        init_scene();
                    }
                );
            }
            
            set_model_3d( jsonFile[ index ] );
        }
    }
}


function show_full_model_3d() {

    for (let item in list_model_3d) {
        list_model_3d[ item ].visible = true;
    }
    
    for (let item in list_light_3d) {
        list_light_3d[ item ].visible = true;
    }

    bakedTexture = textureLoader.load(
        'model/step/baked-27.jpg',

        // onLoad callback
        function ( texture ) {

            set_baked_texture();
           
            gsap.fromTo( dom.webgl, { autoAlpha: 0 }, { autoAlpha: 1, duration: 0.5, onComplete: () => {
                dom.webgl.classList.add( classes.show );
            } } );
        }
    );
}

function start_lock_project() {

    isLockStep = true;
    dom.lock.container.classList.add( classes.show );
    dom.lock.button.addEventListener( 'click', click_button_lock_project );
    dom.lock.container.addEventListener( 'mousemove', mouse_move_lock_project );
}

function mouse_move_lock_project( event ) {
    mouseX = event.clientX;
    mouseY = event.clientY;
}

function click_button_lock_project( event ) {

    if( jsonFileStart.unlocked == true ) {
        return;
    }

    // Init classes
    dom.lock.error.classList.remove( classes.show );

    // If input is not empty
    if( dom.lock.input.value != "" ) {

        if( env == 'prod' ) {
            
            let datatoSend = new FormData();
            datatoSend.append( 'checkPassword', true );
            datatoSend.append( 'password', dom.lock.input.value );
        
            const xhr = new XMLHttpRequest();
            xhr.open('POST', '/php/ajax.php', true);
            xhr.onreadystatechange = function () {
                if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
                    let response = JSON.parse(xhr.response);

                    if( response.success == true ) {
                        jsonFileStart.unlocked = true;
                        lock_project_success();
                    } else {
                        // console.log('mauvaise réponse !')
                        lock_project_error();
                    }
                }
            }.bind(this);
            xhr.send(datatoSend);
        }

        if( env == 'dev' ) {

            if( dom.lock.input.value == jsonFileStart.password ) {
                jsonFileStart.unlocked = true;
                lock_project_success();
            } else {
                // console.log('mauvaise réponse !')
                lock_project_error();
            }
        }
    } else {
        gsap.fromTo( dom.lock.input, {x: -15}, { duration: 1, ease: "elastic.out(1, 0.3)", x: 0 });
    }
}

function lock_project_error() {
    dom.lock.error.classList.add( classes.show );
}

function lock_project_success() {

    let timeline = gsap.timeline({
        onComplete: end_anim_unlock_project
    });
    timeline
        .fromTo( [ dom.lock.title, dom.lock.input, dom.lock.button ], { y: 0 }, { y: -100, duration: 0.4, stagger: 0.05 }, 'start' )
        // .fromTo( [ dom.lock.title, dom.lock.input, dom.lock.button ], { autoAlpha: 1 }, { autoAlpha: 0, duration: 0.3, stagger: 0.05 }, 'start+=0.1' )
        .fromTo( [ dom.lock.title, dom.lock.input, dom.lock.button ], { autoAlpha: 1 }, { autoAlpha: 0, duration: 0.3, stagger: 0.05 }, 'start' )
        .fromTo( dom.lock.success, { y: 100 }, { y: 0, duration: 0.4, stagger: 0.05 }, 'start+=0.5' )
        .fromTo( dom.lock.success, { autoAlpha: 0 }, { autoAlpha: 1, duration: 0.3, stagger: 0.05 }, 'start+=0.5' )
}

function end_anim_unlock_project() {
    // console.log('end_anim_unlock_project')
    setTimeout( () => {
        start_unlock_project();
    }, 1000 );
    
}

function start_unlock_project() {
    load_json();
}

function load_json() {

    if( env == 'prod' ) {

        let datatoSend = new FormData();
        datatoSend.append( 'getListQuestion', true );
    
        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/php/ajax.php', true);
        xhr.onreadystatechange = function () {
            if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
                let response = JSON.parse(xhr.response);
                nateNow = response.now;
                jsonFile = response.list_question;
                json_loaded = true;
                // init_scene();
            }
        }.bind(this);
        xhr.send(datatoSend);
    }

    if( env == 'dev' ) {
        fetch('/json/questions.json')
        .then( ( response ) => response.json() )
        .then( ( json ) =>  { 
            jsonFile = json;
            nateNow = Date.now();
            json_loaded = true;
            // init_scene(); 
        } );
    }
}

function init_scene() {

    count_question = Object.keys(jsonFile).length;

    for (let index in jsonFile) {

        if( jsonFile.hasOwnProperty( index ) ) {

            // Create DOM
            create_dom_question( index, jsonFile[ index ] );
        }
    }

    // Create Event in new DOM
    create_dom_event();

    // Show DOM
    show_scene();
}

function show_scene() {

    let timeline = gsap.timeline({
        onComplete: end_anim_show_scene
    });
    
    if( isLockStep ){
        timeline
            .fromTo( dom.lock.container, { autoAlpha: 1 }, { autoAlpha: 0, duration: 0.5 }, 'start' )
    }

    timeline
        .fromTo( [ dom.scene.container, dom.webgl ], { autoAlpha: 0 }, { autoAlpha: 1, duration: 0.5 }, 'start' )
        .fromTo( dom.question.items, { y: 100 }, { y: 0, duration: 0.4, stagger: 0.05 }, 'start+=0.3' ) // ou "stagger: -0.05" pour faire dans le sens inverse des éléments
        .fromTo( dom.question.items, { autoAlpha: 0 }, { autoAlpha: 1, duration: 0.3, stagger: 0.05 }, 'start+=0.3' ) // ou "stagger: -0.05" pour faire dans le sens inverse des éléments
}

function end_anim_show_scene() {

    if( isLockStep ){
        isLockStep = false;
        dom.lock.button.removeEventListener( 'click', click_button_lock_project );
        dom.lock.container.removeEventListener( 'mousemove', mouse_move_lock_project );
        dom.lock.container.classList.remove( classes.show );
    }


    dom.webgl.classList.add( classes.show );
    dom.scene.container.classList.add( classes.show );
}

function create_dom_question( index, item ) {

    // Creation of DOM elements for each question
    let fragment = document.createDocumentFragment();

    let questionItem = document.createElement('div',  {
        classname: classes.questionDOMItem,
    });
    questionItem.className = classes.questionDOMItem;
    questionItem.classList.add( classes.questionItem );

    let itemDate = ( env == 'prod' ) ? item.date : new Date( item.date.year, item.date.month, item.date.day, 0, 0, 0 ).getTime();

    let classState = classes.pending;
    if( item.state == classes.success ) {
        classState = classes.success;
    } else if( index == 0 || ( jsonFile[ index - 1 ].state == classes.success && itemDate <= nateNow ) ) {
        classState = classes.current;
    }
    questionItem.classList.add( classState );

    let questionContent = document.createElement('div',  {
    });
    questionContent.className = classes.questionDOMItemContent;
    questionItem.appendChild( questionContent );

    let questionBorder = document.createElement('div');
    questionBorder.className = classes.questionDOMItemBorder;
    questionContent.appendChild( questionBorder );
    
    questionBorder = document.createElement('div');
    questionBorder.className = classes.questionDOMItemBorder;
    questionContent.appendChild( questionBorder );

    fragment.appendChild( questionItem );
    dom.question.container.appendChild(fragment);

    dom.question.items = [].slice.call( dom.body.querySelectorAll( '.' + classes.questionItem ) );
}

function set_model_3d( item ) {

    if( item.state == classes.success ) {

        list_model_3d[ item.model_name ].visible = true;

        if( item.hasOwnProperty( 'model_light' ) && item.model_light.length > 0 ) {
            item.model_light.map( (item ) => list_light_3d[ item ].visible = true );
        }
    }
}

function set_model_texture( item ) {

    bakedTexture = textureLoader.load(
        'model/step/' + item.model_baking + '.jpg',

        // onLoad callback
        function ( texture ) {
            set_baked_texture();
            set_model_3d( item );
        }
    );
}

function create_dom_event( item ) {

    if( dom.question.items ) {
        for( let i = 0, j = dom.question.items.length; i < j; i++ ) {
            let element = dom.question.items[ i ];
            if( !element.classList.contains( classes.success ) ) {
                element.addEventListener( 'click', clickQuestion );
            }
        }
    }

    dom.popin.close.container.addEventListener( 'click', click_close_popin );
    dom.popin.button.addEventListener( 'click', click_button_popin );
}

function clickQuestion( event ) {

    let element = event.currentTarget;
    let index = dom.question.items.indexOf( element );

    if( element.classList.contains( classes.success ) ) {
        return;
    }

    if( popinOpen )
        return;

    popinOpen = true;

    let itemJson = jsonFile[ index ];
    let itemDate = ( env == 'prod' ) ? itemJson.date : new Date( itemJson.date.year, itemJson.date.month, itemJson.date.day, 0, 0, 0 ).getTime();

    if( index != 0 && jsonFile[ index - 1 ].state != "success" ) {

        // console.log('il faut répondre a la question d avant !!! ')
        dom.popin.container.classList.add( classes.showInfoFast );

    }
    else if( index == 0 || itemDate <= nateNow  ) {

        questionOpenIndex = index;
        // console.log('ok afficher question')
    }
    else {
        // console.log('trop tôt pour connaitre la suite ')
        dom.popin.container.classList.add( classes.showInfoWaiting );
    }
    
    if( questionOpenIndex !== false && typeof itemJson.question === "undefined" && env == 'prod' ) {

        let datatoSend = new FormData();
        datatoSend.append( 'getQuestion', true );
        datatoSend.append( 'id', itemJson.id );
    
        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/php/ajax.php', true);
        xhr.onreadystatechange = function () {
            if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
                let response = JSON.parse(xhr.response);
                jsonFile[ index ].question = response.question;
                prepare_popin( element );
            }
        }.bind(this);
        xhr.send(datatoSend);

        
    } else {
        prepare_popin( element );
    }
}

function prepare_popin( element ) {

    // Cas particulier : question 2 => text reverse
    if( questionOpenIndex !== false ) {

        dom.popin.question.content.innerHTML = jsonFile[ questionOpenIndex ].question;

        if( jsonFile[ questionOpenIndex ].index == 1 ) {
            dom.popin.container.classList.add( classes.reverse );
        }
    }

    show_popin();

    questionOpenDOM = element;
    questionOpenDOM.classList.add( classes.active );
}

function show_popin() {
    
    let timeline = gsap.timeline({
        onComplete: end_anim_show_popin
    });

    let border_popin = 6;
    timeline
        .set( dom.popin.container, {autoAlpha: 1 } )
        .fromTo( 
            dom.popin.border.top, 
            { 'clip-path': 'polygon( 0 0, 0 0, 0 ' + border_popin + 'px, 0 ' + border_popin + 'px )' }, 
            { 
                'clip-path': 'polygon( 0 0, 100% 0, 100% ' + border_popin + 'px, 0 ' + border_popin + 'px )',
                duration: 0.5 
            }, 
            'start'
        )
        .fromTo( 
            dom.popin.border.right, 
            { 'clip-path': 'polygon( calc( 100% - ' + border_popin + 'px ) 0, 100% 0, 100% 0, 100% 0 )' }, 
            { 
                'clip-path': 'polygon( calc( 100% - ' + border_popin + 'px ) 0, 100% 0, 100% 100%, calc( 100% - ' + border_popin + 'px ) 100% )',
                duration: 0.5 
            }, 
            'start+=0.4'
        )
        .fromTo( 
            dom.popin.border.bottom, 
            { 'clip-path': 'polygon( 0 calc( 100% - ' + border_popin + 'px ), 0 calc( 100% - ' + border_popin + 'px ), 0 100%, 0 100% )' }, 
            { 
                'clip-path': 'polygon( 0 calc( 100% - ' + border_popin + 'px ), 100% calc( 100% - ' + border_popin + 'px ), 100% 100%, 0 100% )',
                duration: 0.5 
            }, 
            'start'
        )
        .fromTo( 
            dom.popin.border.left, 
            { 'clip-path': 'polygon( 0 0, ' + border_popin + 'px 0, ' + border_popin + 'px 0 ), 0 0' }, 
            { 
                'clip-path': 'polygon( 0 0, ' + border_popin + 'px 0, ' + border_popin + 'px 100%, 0 100% )',
                duration: 0.5 
            }, 
            'start+=0.4'
        )
        .fromTo( dom.popin.content, { autoAlpha: 0 }, { autoAlpha: 1, duration: 0.3 }, 'start+=0.8' )
        .fromTo( dom.popin.close.container, { scaleY: 0 }, { scaleY: 1, duration: 0.2 }, 'start+=0.8' )
        .fromTo( dom.popin.close.cross, { autoAlpha: 0 }, { autoAlpha: 1, duration: 0.2 }, 'start+=0.9' )
        .fromTo( [ dom.popin.question.container, dom.popin.input, dom.popin.button ], { y: 20 }, { y: 0, duration: 0.3, stagger: 0.05 }, 'start+=1' )
        .fromTo( [ dom.popin.question.container, dom.popin.input, dom.popin.button ], { autoAlpha: 0 }, { autoAlpha: 1, duration: 0.4, stagger: 0.05 }, 'start+=1.1' ) 
}

function end_anim_show_popin() {
    dom.popin.container.classList.add( classes.show );
}

function click_close_popin( event ) {
    anim_close_popin();
}

function anim_close_popin() {
    gsap.fromTo( dom.popin.container, { autoAlpha: 1 }, { autoAlpha: 0, duration: 0.4, onComplete: resetPopin } ); 
}

function resetPopin() {

    if( questionOpenIndex !== false ) {
        dom.question.items[ questionOpenIndex ].classList.remove( classes.toAnimate );
    }

    if( questionOpenDOM !== false ) {
        questionOpenDOM.classList.remove( classes.active );
    }
    
    questionOpenDOM = false;
    questionOpenIndex = false;
    dom.popin.question.content.innerHTML = '';
    dom.popin.input.value = '';

    dom.popin.error.classList.remove( classes.show );
    dom.popin.container.classList.remove( classes.show );
    dom.popin.container.classList.remove( classes.showInfoWaiting );
    dom.popin.container.classList.remove( classes.showInfoFast );
    dom.popin.container.classList.remove( classes.reverse );

    gsap.set( [ dom.popin.container, dom.popin.close.container, dom.popin.close.cross, dom.popin.border.top, dom.popin.border.right, dom.popin.border.bottom, dom.popin.border.left, dom.popin.content, dom.popin.question.container, dom.popin.input, dom.popin.button, dom.popin.success ], { clearProps: 'all' } );

    popinOpen = false;
}

function closePopin() {}

function click_button_popin( event ) {

    if( questionOpenIndex === false )
        return; 

    // Init classes
    dom.popin.error.classList.remove( classes.show );

    let valueInput = dom.popin.input.value;
    let itemJson = jsonFile[ questionOpenIndex ];

    if( valueInput != '' ) {

        valueInput = valueInput.toLowerCase();

        if( env == 'prod' ) {
            
            let datatoSend = new FormData();
            datatoSend.append( 'checkQuestion', true );
            datatoSend.append( 'id', itemJson.id );
            datatoSend.append( 'answer', valueInput );
        
            const xhr = new XMLHttpRequest();
            xhr.open('POST', '/php/ajax.php', true);
            xhr.onreadystatechange = function () {
                if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
                    let response = JSON.parse(xhr.response);
                    checkResponse( response );
                }
            }.bind(this);
            xhr.send(datatoSend);
        }

        if( env == 'dev' ) {

            let list_word_answer = itemJson.answer.split(" ");
            let count_word_existing = 0;
            for( let i = 0, j = list_word_answer.length; i < j; i++ ) {

                if( valueInput.search( list_word_answer[ i ] ) !== -1 ) {
                    count_word_existing ++;
                }
            }
            if( count_word_existing == list_word_answer.length ) { //if( itemJson.answer === valueInput ) {
                checkResponse( { success : true } );
            } else {
                checkResponse( { success : false } );
            }
        }
    } else {
        gsap.fromTo( dom.popin.input, {x: -15}, { duration: 1, ease: "elastic.out(1, 0.3)", x: 0 });
    }
}

function checkResponse( response ) {

    if( response.success ) {

        jsonFile[ questionOpenIndex ]['state'] = classes.success;
        jsonFile[ questionOpenIndex ]['time'] = Date.now();

        anim_question_success();

    } else {
        // console.log('mauvaise réponse')
        dom.popin.error.classList.add( classes.show );
    }
}

function anim_question_success() {

    let timeline = gsap.timeline({
        onComplete: end_anim_question_success
    });
    timeline
        .fromTo( [ dom.popin.question.container, dom.popin.input, dom.popin.button ], { y: 0 }, { y: -30, duration: 0.4, stagger: 0.05 }, 'start' )
        .fromTo( [ dom.popin.question.container, dom.popin.input, dom.popin.button ], { autoAlpha: 1 }, { autoAlpha: 0, duration: 0.3, stagger: 0.05 }, 'start' )
        .fromTo( dom.popin.success, { y: 30 }, { y: 0, duration: 0.4, stagger: 0.05 }, 'start+=0.5' )
        .fromTo( dom.popin.success, { autoAlpha: 0 }, { autoAlpha: 1, duration: 0.3, stagger: 0.05 }, 'start+=0.5' )

}

function end_anim_question_success() {

    dom.question.items[ questionOpenIndex ].classList.remove( classes.pending );
    dom.question.items[ questionOpenIndex ].classList.remove( classes.current );
    dom.question.items[ questionOpenIndex ].classList.add( classes.toAnimate );
    dom.question.items[ questionOpenIndex ].classList.add( classes.success );

    set_model_texture( jsonFile[ questionOpenIndex ] );

    // set_model_3d( jsonFile[ questionOpenIndex ] );

    let nextQuestion = jsonFile[ questionOpenIndex + 1 ];
    
    if( nextQuestion ) {
        let nextQuestionDate = ( env == 'prod' ) ? nextQuestion.date : new Date( nextQuestion.date.year, nextQuestion.date.month, nextQuestion.date.day, 0, 0, 0 ).getTime();
    
        if( nextQuestionDate <= nateNow ) {
            dom.question.items[ questionOpenIndex + 1 ].classList.remove( classes.pending );
            dom.question.items[ questionOpenIndex + 1 ].classList.add( classes.current );
        }
    }

    setTimeout( () => {
        if( popinOpen ) {
            anim_close_popin();
        }        
    }, 500 );

    // Game finished
    if( typeof nextQuestion == 'undefined' ) {
         // console.log('jeu terminé')
        setTimeout( () => {
            init_end_game();     
        }, 900 );
    }
}

function init_end_game() {

    if( env == 'prod' ) {

        let datatoSend = new FormData();
        datatoSend.append( 'setEndGame', true );
    
        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/php/ajax.php', true);
        xhr.onreadystatechange = function () {
            if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
                jsonFileStart.end_game_unlock = true;
                start_end_project();
            }
        }.bind(this);
        xhr.send(datatoSend);
    }

    if( env == 'dev' ) {
        jsonFileStart.end_game_unlock = true;
        start_end_project();
    }
}

function start_end_project() {

    if( env == 'prod' ) {

        let datatoSend = new FormData();
        datatoSend.append( 'endGame', true );
    
        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/php/ajax.php', true);
        xhr.onreadystatechange = function () {
            if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
                jsonFileEnd = JSON.parse(xhr.response);
                show_end_game();
            }
        }.bind(this);
        xhr.send(datatoSend);
    }

    if( env == 'dev' ) {

        fetch('/json/end.json')
        .then( ( response ) => response.json() )
        .then( ( json ) =>  { 
            jsonFileEnd = json;
            setTimeout(() => { show_end_game(); }, 1000 );
        } );
    }
}

function show_end_game() {

    dom.end.text.innerHTML = jsonFileEnd.text;

    let timeline = gsap.timeline({
        onComplete: () => {
            dom.end.close.cross.addEventListener( 'click', click_close_end_popin );
        }
        
    });
    timeline
        .set( dom.end.container, { autoAlpha: 1 } )
        .fromTo( dom.end.content, { scaleX: 0 }, { scaleX: 1, duration: 1.2, ease: "power4.out" }, 'start' )
        .fromTo( dom.end.contentText, { y: 50 }, { y: 0, duration: 1.5, ease: "power4.out" }, 'start+=1.1' )
        .fromTo( dom.end.contentText, { autoAlpha: 0 }, { autoAlpha: 1, duration: 1.2, ease: "power4.out" }, 'start+=1.2' )
        .fromTo( dom.end.close.container, { scaleY: 0 }, { scaleY: 1, duration: 0.5, ease: "power4.out" }, 'start+=1.2' )
        .fromTo( dom.end.close.cross, { autoAlpha: 0 }, { autoAlpha: 1, duration: 0.5, ease: "power4.out" }, 'start+=1.5' )

    confettiClass = new Confetti();
}

function click_close_end_popin() {

    let timeline = gsap.timeline({
        onComplete: () => {
            gsap.set( [ dom.end.container, dom.end.contentText, dom.end.close.cross, dom.end.close.container, dom.end.content ], { clearProps: 'all' } );
            confettiClass = false;
        }
    });
    timeline
        .fromTo( dom.end.contentText, { autoAlpha: 1 }, { autoAlpha: 0, duration: 1.2, ease: "power4.out" }, 'start' )
        .fromTo( dom.end.close.cross, { autoAlpha: 1 }, { autoAlpha: 0, duration: 0.5, ease: "power4.out" }, 'start' )
        .fromTo( dom.end.close.container, { scaleY: 1 }, { scaleY: 0, duration: 0.5, ease: "power4.out" }, 'start+=0.5' )
        .fromTo( dom.end.content, { scaleX: 1 }, { scaleX: 0, duration: 1.2, ease: "power4.out" }, 'start+=1.2' )
        .fromTo( [ dom.end.container, dom.confetti ], { autoAlpha: 1 }, { autoAlpha: 0 }, 'start+=2' )
}






































/**
 * Base
 */
// Debug
const debugObject = {}
const gui = new GUI({
    width: 400
})
gui.close();
gui.hide();

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()




/**
 * Textures
 */
function set_baked_texture( name ) {

    bakedTexture.flipY = false;
    bakedTexture.colorSpace = THREE.SRGBColorSpace;

    bakedMaterial.map = bakedTexture;
    bakedMaterial.needsUpdate = true;
}

/**
 * Model
 */
function init_model_3d() {

    gltfLoader.load(
        'model/ewing.glb',
        (gltf) =>
        {
            // console.log(gltf.scene)
            scene.add(gltf.scene)

            // gltf.scene.traverse( ( child ) => {
            //     child.material = bakedMaterial
            // })

            // Get each object
            for( let i = 0, j = gltf.scene.children.length; i < j; i++ ) {
                let child = gltf.scene.children[ i ];
                if( list_name.includes( child.name ) !== false ) {
                    list_model_3d[ child.name ] = child;

                    list_model_3d[ child.name ].material = bakedMaterial;
                    // list_model_3d[ child.name ].material.wireframe = true;

                    if( child.name != 'floor' ) {
                        list_model_3d[ child.name ].visible = false;
                    }
                }
            }

            // Get all lights
            list_light_3d.yellow_light_1 = gltf.scene.children.find((child) => child.name === 'yellow_light_1')
            list_light_3d.yellow_light_2 = gltf.scene.children.find((child) => child.name === 'yellow_light_2')
            list_light_3d.red_light_1 = gltf.scene.children.find((child) => child.name === 'red_light_1')
            list_light_3d.red_light_2 = gltf.scene.children.find((child) => child.name === 'red_light_2')
            list_light_3d.button_1 = gltf.scene.children.find((child) => child.name === 'button_1')
            list_light_3d.button_2 = gltf.scene.children.find((child) => child.name === 'button_2')
            
            // Apply materials to light
            list_light_3d.yellow_light_1.material = yellowLightMaterial
            list_light_3d.yellow_light_2.material = yellowLightMaterial
            list_light_3d.button_1.material = yellowLightMaterial
            list_light_3d.red_light_1.material = redLightMaterial
            list_light_3d.red_light_2.material = redLightMaterial
            list_light_3d.button_2.material = redLightMaterial

            // Hide light
            list_light_3d.yellow_light_1.visible = false;
            list_light_3d.yellow_light_2.visible = false;
            list_light_3d.button_1.visible = false;
            list_light_3d.red_light_1.visible = false;
            list_light_3d.red_light_2.visible = false;
            list_light_3d.button_2.visible = false;

            init_model_3d_loaded = true;
        }
    )
}
/**
 * Material
 */
// Light material
debugObject.yellowColor = '#ffffe5'
debugObject.redColor = '#FF432D'
const yellowLightMaterial = new THREE.MeshBasicMaterial( { color: debugObject.yellowColor  } );
const redLightMaterial = new THREE.MeshBasicMaterial( { color: debugObject.redColor } );

gui
    .addColor(debugObject, 'yellowColor')
    .onChange(() =>
    {
        yellowLightMaterial.color.set( debugObject.yellowColor )
    })

gui
    .addColor(debugObject, 'redColor')
    .onChange(() =>
    {
        redLightMaterial.color.set( debugObject.redColor )
    })


/**
 * Fireflies
 */
// Geometry
const firefliesGeometry = new THREE.BufferGeometry()
const firefliesCount = 400
const positionArray = new Float32Array(firefliesCount * 3)
const scaleArray = new Float32Array(firefliesCount)

for(let i = 0; i < firefliesCount; i++)
{

    let angle = Math.random() * (2 * Math.PI );
    let radius = ( Math.random() * 35 ) + 20;
    positionArray[i * 3 + 0] =  Math.cos( angle ) * radius;
    positionArray[i * 3 + 1] = Math.random() * 50
    positionArray[i * 3 + 2] =  Math.sin( angle ) * radius;

    // let test = [ -1, 1 ];
    // let randomElement = test[ Math.floor(Math.random() * test.length ) ];
    // positionArray[i * 3 + 0] =  ( ( Math.random() * 10 ) + 20 ) * randomElement//( ( Math.random() - 0.5 ) * 80 ) + 50
    // positionArray[i * 3 + 1] = Math.random() * 50
    // positionArray[i * 3 + 2] = ( ( Math.random() * 10 ) + 20 ) * randomElement //( ( Math.random() - 0.5 ) * 80 ) + 50

    scaleArray[i] = Math.random() * 10
}

firefliesGeometry.setAttribute('position', new THREE.BufferAttribute(positionArray, 3))
firefliesGeometry.setAttribute('aScale', new THREE.BufferAttribute(scaleArray, 1))

// Material
const firefliesMaterial = new THREE.ShaderMaterial({
    uniforms:
    {
        uTime: { value: 0 },
        uPixelRatio: { value: Math.min(window.devicePixelRatio, 2) },
        uSize: { value: 100 }
    },
    vertexShader: firefliesVertexShader,
    fragmentShader: firefliesFragmentShader,
    transparent: true,
    blending: THREE.AdditiveBlending,
    depthWrite: false
})

gui.add(firefliesMaterial.uniforms.uSize, 'value').min(0).max(500).step(1).name('firefliesSize')

// Points
const fireflies = new THREE.Points(firefliesGeometry, firefliesMaterial)
scene.add(fireflies)

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

    // Update fireflies
    firefliesMaterial.uniforms.uPixelRatio.value = Math.min(window.devicePixelRatio, 2)
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(45, sizes.width / sizes.height, 0.1, 230)
camera.position.x = 70; //4
camera.position.y = 35; //2
camera.position.z = 30; //4
scene.add(camera)

// Controls
const controls = new OrbitControls(camera, canvas)
controls.enableDamping = true

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    antialias: true,
    // alpha: true
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))


debugObject.clearColor = '#4d2461' // '#6d4061' // 3d065b // 410561 // C4E2E7

/**
 * Fog
 */
const fog = new THREE.Fog(debugObject.clearColor, 10, 135);
scene.fog = fog;

renderer.setClearColor(debugObject.clearColor)
// renderer.setClearColor( 0x000000, 0 ); // Alpha 0 for background (if CSS background)

gui
    .addColor(debugObject, 'clearColor')
    .onChange(() =>
    {
        renderer.setClearColor(debugObject.clearColor)
        // fog.setClearColor(debugObject.clearColor)
        fog.color = new THREE.Color(  debugObject.clearColor )
    })

/**
 * Animate
 */
const clock = new THREE.Clock()

const tick = () =>
{

    // Show model 3D in the init project
    if( init_model_3d_loaded && json_loaded && init_project_first_time == false ) {
        init_project_first_time = true;
        init_show_model_3d();
    }

    // If end game at the beginning
    if( init_model_3d_loaded && init_end_first_time ) {
        init_end_first_time = false;
        show_full_model_3d();
    }

    // Start
    if( isLockStep == true ) {
        // currentX += ( mouseX - currentX ) / 20; // Version 1 (voir CSS)
        // currentY += ( mouseY - currentY ) / 20; // Version 1
        currentX += ( ( mouseX - ( dom.lock.mouse.getBoundingClientRect().width / 2 ) ) - currentX ) / 20; // Version 2
        currentY += ( ( mouseY - ( dom.lock.mouse.getBoundingClientRect().height / 2 ) ) - currentY ) / 20;  // Version 2
        gsap.set( dom.lock.mouse, { translateX: currentX, translateY: currentY } );
        // dom.lock.mouse.style.transform = `translate(${Math.round(currentX)}px, ${Math.round(currentY)}px)`;
    }

    // End
    if( confettiClass ) {
        confettiClass.loop();
    }

    const elapsedTime = clock.getElapsedTime()

    // Update materials
    firefliesMaterial.uniforms.uTime.value = elapsedTime

    // Update controls
    controls.update()

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()
