
export default class Rectangle {

    TWO_PI = Math.PI * 2;
    HALF_PI = Math.PI * 0.5;

    anim_particule = false;

    // canvas settings
    viewWidth = 1000;
    viewHeight = 1000;
    ctx = false;
    drawingCanvas = document.body.querySelector( '.js-confetti-canvas' );

    particles = [];
    loader;
    exploader;
    phase = 0;

    /**
     * easing equations from http://gizma.com/easing/
     * t = current time
     * b = start value
     * c = delta value
     * d = duration
     */
    Ease = {
        inCubic:function (t, b, c, d) {
            t /= d;
            return c*t*t*t + b;
        },
        outCubic:function(t, b, c, d) {
            t /= d;
            t--;
            return c*(t*t*t + 1) + b;
        },
        inOutCubic:function(t, b, c, d) {
            t /= d/2;
            if (t < 1) return c/2*t*t*t + b;
            t -= 2;
            return c/2*(t*t*t + 2) + b;
        },
        inBack: function (t, b, c, d, s) {
            s = s || 1.70158;
            return c*(t/=d)*t*((s+1)*t - s) + b;
        }
    };

    constructor() {

        this.initDrawingCanvas();

        // this.loop();
    }

    initDrawingCanvas() {
        this.drawingCanvas.width = this.viewWidth;
        this.drawingCanvas.height = this.viewHeight;
        this.ctx = this.drawingCanvas.getContext('2d');
    
        this.createLoader();
        this.createExploader();
        this.createParticles();
    
        this.anim_particule = true;
    }

    createLoader() {
        this.loader = new Loader( this.viewWidth * 0.5, this.viewHeight * 0.5, this.ctx );
    }

    createExploader() {
        this.exploader = new Exploader( this.viewWidth * 0.5, this.viewHeight * 0.5, this.ctx, this.Ease );
    }

    createParticles() {
        for (var i = 0; i < 128; i++) {
            var p0 = new Point(this.viewWidth * 0.5, this.viewHeight * 0.5);
            var p1 = new Point(Math.random() * this.viewWidth, Math.random() * this.viewHeight);
            var p2 = new Point(Math.random() * this.viewWidth, Math.random() * this.viewHeight);
            var p3 = new Point(Math.random() * this.viewWidth, this.viewHeight + 64);
    
            this.particles.push( new Particle( p0, p1, p2, p3, this.ctx, this.Ease ) );
        }
    }

    update() {

        switch (this.phase) {
            case 0:
                this.loader.set_progress( this.loader._progress + (1/45));
                break;
            case 1:
                this.exploader.update();
                break;
            case 2:
                this.particles.forEach(function(p) {
                    p.update();
                });
                break;
        }
    }

    draw() {
        this.ctx.clearRect(0, 0, this.viewWidth, this.viewHeight);
    
        switch (this.phase) {
            case 0:
                this.loader.draw();
                break;
            case 1:
                this.exploader.draw();
                break;
            case 2:
                this.particles.forEach(function(p) {
                    p.draw();
                });
            break;
        }
    }

    checkParticlesComplete() {
        for (var i = 0; i < this.particles.length; i++) {
            if (this.particles[i].complete === false) return false;
        }
        return true;
    }

    loop() {
        if( this.anim_particule ) {

            this.update();
            this.draw();
        
            if (this.phase === 0 && this.loader.complete) {
                this.phase = 1;
            }
            else if (this.phase === 1 && this.exploader.complete) {
                this.phase = 2;
            }
            else if (this.phase === 2 && this.checkParticlesComplete()) {
                // reset
                this.phase = 0;
                this.loader.reset();
                this.exploader.reset();
                this.particles.length = 0;
                this.createParticles();
            }
        }

        // Call tick again on the next frame
        // window.requestAnimationFrame(this.loop.bind( this ) );
    }
}

class Loader {

    TWO_PI = Math.PI * 2;
    HALF_PI = Math.PI * 0.5;
    
    constructor(x, y, ctx) {

        this.x = x;
        this.y = y;
        this.ctx = ctx;
      
        this.r = 24;
        this._progress = 0;

        this.complete = false;
    }

    reset() {
        this._progress = 0;
        this.complete = false;
    }

    set_progress(p) {
        this._progress = p < 0 ? 0 : (p > 1 ? 1 : p);

        this.complete = this._progress === 1;
    }
    
    get_progress() {
        return this._progress;
    }

    draw() {
        this.ctx.fillStyle = 'transparent';
        this.ctx.beginPath();
        this.ctx.arc(this.x, this.y, this.r, -this.HALF_PI, this.TWO_PI * this._progress - this.HALF_PI);
        this.ctx.lineTo(this.x, this.y);
        this.ctx.closePath();
        this.ctx.fill();
    }
}

// pun intended
class Exploader {

    TWO_PI = Math.PI * 2;
    HALF_PI = Math.PI * 0.5;
    timeStep = (1/60);

    constructor(x,y, ctx, ease ) {   
        
        this.x = x;
        this.y = y;
        this.ctx = ctx;
        this.ease = ease;

        this.startRadius = 24;

        this.time = 0;
        this.duration = 0.4;
        this.progress = 0;

        this.complete = false;
    }

    reset() {
        this.time = 0;
        this.progress = 0;
        this.complete = false;
    }

    draw() {
        this.ctx.fillStyle = 'transparent';
        this.ctx.beginPath();
        this.ctx.arc(this.x, this.y, this.startRadius * (1 - this.progress), 0, this.TWO_PI);
        this.ctx.fill();
    }

    update() {
        this.time = Math.min(this.duration, this.time + this.timeStep);
        this.progress = this.ease.inBack(this.time, 0, 1, this.duration);

        this.complete = this.time === this.duration;
    }
}

class Point {
    constructor(x,y) {
        this.x = x || 0;
        this.y = y || 0;
    }
}

class Particle {

    TWO_PI = Math.PI * 2;
    HALF_PI = Math.PI * 0.5;
    timeStep = (1/60);
    
    constructor( p0, p1, p2, p3, ctx, ease ) {
        this.p0 = p0;
        this.p1 = p1;
        this.p2 = p2;
        this.p3 = p3;
        this.ctx = ctx;
        this.ease = ease;

        this.time = 0;
        this.duration = 3 + Math.random() * 2;
        this.color =  '#' + Math.floor((Math.random() * 0xffffff)).toString(16);

        this.w = 8;
        this.h = 6;

        this.complete = false;
    }

    update() {
        this.time = Math.min(this.duration, this.time + this.timeStep);

        var f = this.ease.outCubic(this.time, 0, 1, this.duration);
        var p = this.cubeBezier(this.p0, this.p1, this.p2, this.p3, f);

        var dx = p.x - this.x;
        var dy = p.y - this.y;

        this.r =  Math.atan2(dy, dx) + this.HALF_PI;
        this.sy = Math.sin(Math.PI * f * 10);
        this.x = p.x;
        this.y = p.y;

        this.complete = this.time === this.duration;
    }

    draw() {
        this.ctx.save();
        this.ctx.translate(this.x, this.y);
        this.ctx.rotate(this.r);
        this.ctx.scale(1, this.sy);

        this.ctx.fillStyle = this.color;
        this.ctx.fillRect(-this.w * 0.5, -this.h * 0.5, this.w, this.h);

        this.ctx.restore();
    }

    // math and stuff
    cubeBezier(p0, c0, c1, p1, t) {
        var p = new Point();
        var nt = (1 - t);
    
        p.x = nt * nt * nt * p0.x + 3 * nt * nt * t * c0.x + 3 * nt * t * t * c1.x + t * t * t * p1.x;
        p.y = nt * nt * nt * p0.y + 3 * nt * nt * t * c0.y + 3 * nt * t * t * c1.y + t * t * t * p1.y;
    
        return p;
    }
}





